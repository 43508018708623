// themecolor
$themecolor: #461886;

$primarybtn: #461886;

$white: #fff;

$mate-black: #212121;

$black: #474646;

$gradient-one: #461886;

$gradient-two: #7340ba;

$footer-back-color:#000;

$header-top-color:#000;

$breadcrumbs-color:#000;
